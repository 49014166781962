import moment from 'moment-jalaali'
import store from '../../store/store'
import axios from "@/http/axios/index"
import timezonesData from './timezones'

export function getAvatarUrl(url) {
  return axios.defaults.baseURL.replace('/api', '') + `${axios.defaults.baseURL.endsWith('/') ? '' : '/'}assets/v1` + (url.startsWith('/') ? '' : '/') + url + '?t=0.001'
}

export function getAttachUrl(url) {
  return axios.defaults.baseURL.replace('/api', '') + `${axios.defaults.baseURL.endsWith('/') ? '' : '/'}assets/v1` + (url.startsWith('/') ? '' : '/') + url
}

export function getStaticFileUrl(url) {
  const baseUrl = axios.defaults.baseURL.split('/api')[0]
  return baseUrl + '/statics' + url + '?t=0.001'
}

export function setCookie (name, value, domain, date) {
  const expires = '; max-age=' + 2592000
  let initialDomain = ''
  /* if (date) {
    expires = '; expires=' + date
  } */
  if (domain) {
    initialDomain = '; domain=' + domain
  }
  window.document.cookie = name + '=' + (value || '') + expires + initialDomain + '; path=/'
}

export function getCookie(name) {
  let cookie = {}
  document.cookie.split(';').forEach(function(el) {
    let [k,v] = el.split('=')
    cookie[k.trim()] = v
  })
  return cookie[name]
}

export function deleteCookie(name, domain, path) {
  if (getCookie(name)) {
    document.cookie = name + "=" +
      ((path) ? "; path=" + path : "") +
      ((domain) ? "; domain=" + domain : "") +
      "; expires=Thu, 01-Jan-70 00:00:01 GMT";
  }
}

export function customParseFloat(value, digits = 4) {
  return parseFloat(parseFloat(value).toFixed(digits))
}

export function showLoading () {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.classList.remove('in-active')
    appLoading.style.display = 'block'

    setTimeout(() => {
      appLoading.classList.add('active')
    }, 10)
  }
}

export function hideLoading () {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.classList.remove('active')
    appLoading.classList.add('in-active')

    setTimeout(() => {
      appLoading.style.display = 'none'
    }, 10)
  }
}

export function convertUtcToLocale (time, format = 'jYYYY jMM jDD HH:mm') {
  // const timezone = timezonesData[store.state.setting.clubInfo.club_locale].timezone
  // return moment.utc(time, format.split('j').join('')).utcOffset(timezone).format(format)
  return time ? (store.state.setting.clubInfo.club_locale === 'IRI' ? time.split('-').join('/') : time.split('/').join('-')) : ''

}

export function customTrim (value) {
  return value.replace(/\s+/g, ' ')

}

export function convertLocaleToUtc (time) {
  return time ? time : null
}

export function convertTableFilterDate (time) {
  return time ? time : null
}

export function convertPersianNumberToEnglish (str) {
  let persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g]
  let arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g]

  if (typeof str === 'string') {
    for (let i = 0; i < 10; i++) {
      str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i)
    }
  }
  return str
}

export function convertNumberToEnglishLetter (initialNumber) {
  // System for American Numbering
  const th_val = ['', 'thousand', 'million', 'billion', 'trillion'];
  // System for uncomment this line for Number of English
  // var th_val = ['','thousand','million', 'milliard','billion'];

  const dg_val = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
  const tn_val = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
  const tw_val = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
  initialNumber = addComma(initialNumber.toString());
  initialNumber = initialNumber.replace(/[\, ]/g, '');
  if (initialNumber != parseFloat(initialNumber))
    return 'not a number ';
  let x_val = initialNumber.indexOf('.');
  if (x_val === -1)
    x_val = initialNumber.length;
  if (x_val > 15)
    return 'too big';
  let n_val = initialNumber.split('');
  let convertedNumber = '';
  let sk_val = 0;
  for (let i = 0; i < x_val; i++) {
    if ((x_val - i) % 3 == 2) {
      if (n_val[i] === '1') {
        convertedNumber += tn_val[Number(n_val[i + 1])] + ' ';
        i++;
        sk_val = 1;
      } else if (n_val[i] != 0) {
        convertedNumber += tw_val[n_val[i] - 2] + ' ';
        sk_val = 1;
      }
    } else if (n_val[i] != 0) {
      convertedNumber += dg_val[n_val[i]] + ' ';
      if ((x_val - i) % 3 == 0)
        convertedNumber += 'hundred ';
      sk_val = 1;
    }
    if ((x_val - i) % 3 == 1) {
      if (sk_val)
        convertedNumber += th_val[(x_val - i - 1) / 3] + ' ';
      sk_val = 0;
    }
  }
  if (x_val !== initialNumber.length) {
    let y_val = initialNumber.length;
    convertedNumber += 'point ';
    for (let i = x_val + 1; i < y_val; i++)
      convertedNumber += dg_val[n_val[i]] + ' ';
  }
  return convertedNumber.replace(/\s+/g, ' ');
}

export function formatCurrency(initialvalue, digitNum, blur) {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.

  // get input value
  var value = initialvalue.toString();

  // don't validate empty input
  if (value === "") { return "" }

  // original length
  var original_len = value.length;

  // initial caret position
  // var caret_pos = input.prop("selectionStart");

  // check for decimal
  if (value.indexOf(".") >= 0 && digitNum > 0) {

    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    var decimal_pos = value.indexOf(".");

    // split number by decimal point
    var left_side = value.substring(0, decimal_pos);
    var right_side = value.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatNumber(left_side);

    // validate right side
    right_side = formatNumber(right_side, false);

    // On blur make sure 2 numbers after decimal
    if (blur) {
      for (let i = 0; i < digitNum; i++) {
        right_side += "0";
      }
    }

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, digitNum);

    // join number by .
    value = (left_side || '0') + "." + right_side;

  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    value = customParseFloat(value.toString().split(',').join(''), digitNum)
    value = formatNumber(value);

    // final formatting
    if (blur && digitNum > 0) {
      value = (value || '0').toString() + '.'
      for (let i = 0; i < digitNum; i++) {
        value += "0";
      }
    }
  }

  // send updated string to input
  return value;

  // put caret back in the right position
  // var updated_len = input_val.length;
  // caret_pos = updated_len - original_len + caret_pos;
  // input[0].setSelectionRange(caret_pos, caret_pos);
}

export function addComma (val, blur = false) {
  let price = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: store.state.setting.clubInfo.club_currency_code.toUpperCase().replace('IRT', 'IRR'),
    currencyDisplay: "code"
  }).formatToParts(0).filter(x => x.type !== "currency")
    .map(x => x.value)
    .join("")
    .trim()
  if (['IRR', 'IRT'].indexOf(store.state.setting.clubInfo.club_currency_code.toUpperCase()) > -1) {
    price = parseInt(price)
  }
  const decimalPos = price.toString().indexOf('.')
  let decimalNum = 0
  if (decimalPos > -1 && !price.toString().endsWith('.')) {
    decimalNum = price.toString().split('.')[1].length
  } else {
    // return val
  }
  return formatCurrency(val.toString(), decimalNum, blur)
}

export function formatNumber (val, hasComma = true) {
  let sign = ''

  if (val < 0)
    sign = '-'

  val = val.toString()

  if (val[0] === '00')
    val = val.substr(1, val.length - 1)

  if (hasComma) {
    if (document.dir === 'rtl') {
      return val.toString().replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + sign
    } else {
      return sign + val.toString().replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  } else {
    if (document.dir === 'rtl') {
      return val.toString().replace(/\D/g, '') + sign
    } else {
      return sign + val.toString().replace(/\D/g, '')
    }
  }
}

export function countDecimal (value) {
  if(Math.floor(value) === value) return 0;
  return value.toString().split(".")[1].length || 0;
}

export function addSpace (val, limit) {

  val = val.toString()

  if (val[0] === '0')
    val = val.substr(1, val.length - 1)

  if (limit && val.length > limit)
    val = val.substr(0, limit)

  val = val.toString().replace(/\D/g, '')


  let arr = []
  if (val.substr(0, 4) !== undefined)
    arr.push(val.substr(0, 4))

  if (val.substr(4, 4) !== undefined)
    arr.push(val.substr(4, 4))

  if (val.substr(8, 4) !== undefined)
    arr.push(val.substr(8, 4))

  if (val.substr(12, 4) !== undefined)
    arr.push(val.substr(12, 4))

  let copy = ''
  arr.forEach((elm) => {
    if (elm) {
      if (copy)
        copy += ' '

      copy += elm
    }
  })
  val = copy

  return val
}

/* check permissions */

export function checkUserPermissions (permission) {
  let permissions = store.state.auth.user.permissions || []

  if (Array.isArray(permission)) {
    let hasPermissions = 0
    // let permissions = JSON.parse(localStorage.getItem('permissions'))
    permission.forEach((permission_item) => {
      if (permissions.indexOf(permission_item) > -1) {
        hasPermissions++
      }
    })
    return hasPermissions === permission.length ? 2 : hasPermissions > 0 ? 1 : 0

  } else {
    // let permissions = JSON.parse(localStorage.getItem('permissions'))
    return permissions.indexOf(permission) > -1
  }
}

/* /check permissions */

/* quick sort algorithm */

function swap (items, leftIndex, rightIndex) {
  var temp = items[leftIndex]
  items[leftIndex] = items[rightIndex]
  items[rightIndex] = temp
}

function partition (items, field, left, right) {
  var pivot = items[Math.floor((right + left) / 2)], //middle element
    i = left, //left pointer
    j = right //right pointer
  while (i <= j) {
    while (items[i][field] < pivot[field]) {
      i++
    }
    while (items[j][field] > pivot[field]) {
      j--
    }
    if (i <= j) {
      swap(items, i, j) //sawpping two elements
      i++
      j--
    }
  }
  return i
}

export function quickSort (items, field, left, right) {
  var index
  if (items.length > 1) {
    index = partition(items, field, left, right) //index returned from partition
    if (left < index - 1) { //more elements on the left side of the pivot
      quickSort(items, field, left, index - 1)
    }
    if (index < right) { //more elements on the right side of the pivot
      quickSort(items, field, index, right)
    }
  }
  return items
}

/* /quick sort algorithm */

/* return false if request was sent to server */
export function requestExist(funcName) {
  // console.log(store.state.helper.runningFunctions)
  if (store.state.helper.requestStatus.indexOf(funcName) === -1) {
    store.dispatch('helper/changeRequestStatus', funcName)
    return false
  } else {
    // console.log(store.state.helper.runningFunctions.indexOf(funcName))
    return true
  }
}

export function getTimeFromServer() {
  const time = store.state.helper.currentTime
  const timezone = timezonesData.data().locales[store.state.setting.clubInfo.club_locale].timezone
  return moment(time).utc().utcOffset(timezone).format('YYYY-MM-DD HH:mm:ss')
}
